import type { TRPCClientErrorLike } from "@trpc/client";
import type {
  RouterInput,
  RouterOutput,
  AppRouter,
} from "../../api/src/server/trpc/router";

export type APIInputs = RouterInput;
export type APIOutputs = RouterOutput;
export type APIError = TRPCClientErrorLike<AppRouter>;

export enum APIOrderBy {
  asc = "asc",
  desc = "desc",
}

export const useTrpcClient = () => {
  const { $trpcClient } = useNuxtApp();

  return {
    trpcClient: $trpcClient,
  };
};
